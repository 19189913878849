import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { TmUPDATE_TASK, TmUPLOAD_TASK_MEDIA } from "../../api";
import { getProjectTasks, getAllTaskGroups } from "../../redux/slice/taskManagementSlice";
import axiosFetch from "../../axiosConfig";
import InputField from "../../components/inputs/InputField";
import InputNumber from "../../components/inputs/InputNumber";
import InputDate from "../../components/inputs/InputDate";
import Label1 from "../../components/labels/Label1";
import Heading2 from "../../components/headings/Heading2";
import Button1 from "../../components/buttons/Button1";
import Loader2 from "../../components/loaders/Loader2";
import InputFile from "../../components/inputs/InputFile";
import Modal from "../../components/modal/Modal";

const TmUpdateTask = ({ task, projectId, limit, currentPage }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [showFileAttach, setShowFileAttach] = useState(false);
  const [uploadMedia, setUploadMedia] = useState([]);
  const [uploadMediaPreview, setUploadMediaPreview] = useState([]);
  const [validationError, setValidationError] = useState([]);
  const [uploadMediaResponse, setUploadMediaResponse] = useState({});
  const [fullSizeImage, setFullSizeImage] = useState(false);
  const [updateTaskResponse, setUpdateTaskResponse] = useState({});
  const [updateTaskPayload, setUpdateTaskPayload] = useState({
    title: task.title,
    description: task.description,
    task_id: task.tuid,
    group_id: task?.group?.guid,
    // "event_id": "string",
    estimate_hours: task.estimate_hours,
    deadline_date: task.deadline,
    start_date: task.start_date,
  });
  // getting the project id from props
  const puid = projectId;

  // to get all task groups
  useEffect(() => {
    setLoader(true);
    dispatch(
      getAllTaskGroups({
        projectId: puid,
      })
    ).then(() => {
      setLoader(false);
    });
  }, [puid]);

  // getting all task groups from taskManagement state
  const {
    getTaskGroups: { allTaskGroups },
  } = useSelector((state) => state.taskManagement);

  // function to validate the uploaded image
  const handleUploadedImage = async (fileList) => {
    const files = Array.from(fileList); // Convert FileList to an array
    const allowedExtensions = /\.(jpg|jpeg|xlsx|word|page|pdf|webp|ppt|csv|txt|png)$/i;
    setUploadMediaPreview([]);

    const uploadErrors = [];
    const newUploadMedia = [];
    const newUploadMediaPreview = [];

    files.forEach((file) => {
      const uploadError = {
        size_error: "",
        extension_error: "",
      };

      // Check if file size is greater than 5MB
      if (file.size > 5 * 1024 * 1024) {
        uploadError.size_error = t("profilePhotoSizeError");
      }

      // Check if file extension is not allowed
      if (!allowedExtensions.test(file.name)) {
        uploadError.extension_error = t("profilePhotoExtensionError");
      }

      // Add the error object to the array if any error exists
      if (uploadError.extension_error || uploadError.size_error) {
        uploadErrors.push(uploadError);
      } else {
        newUploadMedia.push(file);
        const reader = new FileReader();
        reader.onload = () => {
          newUploadMediaPreview.push(reader.result);
          // Update the state after reading all files
          if (newUploadMediaPreview.length === newUploadMedia.length) {
            // setUploadMedia((prev) => [...prev, ...newUploadMedia]);
            setUploadMediaPreview((prev) => [...prev, ...newUploadMediaPreview]);
          }
        };
        reader.readAsDataURL(file);
      }
    });

    // Set validation errors if there are any
    if (uploadErrors.length > 0) {
      setValidationError(uploadErrors);
      return;
    }

    setUploadMedia(fileList);
    // Re-enable the update button if no errors
    // setDisableUpdateBtn(false);
  };

  const uploadTaskMedia = async () => {
    try {
      const formData = new FormData();
      Array.from(uploadMedia).forEach((file) => {
        formData.append("files", file); // 'files' is the key for multiple files
      });
      const resp = await axiosFetch.post(TmUPLOAD_TASK_MEDIA + `/${task.tuid}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (resp?.status === 201) {
        dispatch(
          getProjectTasks({
            projectId: puid,
            limit: limit,
            offset: limit * (currentPage - 1),
          })
        );
        setUploadMediaResponse(resp);
      }
    } catch (error) {
      setUploadMediaResponse(error.response);
    }
  };

  // call the API of update task
  const updateTask = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(TmUPDATE_TASK, updateTaskPayload);
      setUpdateTaskResponse(resp.data);
      if (resp.status === 201) {
        setLoader2(false);
        uploadTaskMedia();
        // dispatch getProjectTasks action if task is updated successfully
        dispatch(
          getProjectTasks({
            projectId: puid,
            limit: limit,
            offset: limit * (currentPage - 1),
          })
        );
      }
    } catch (error) {
      // console.log(error.response);
      setUpdateTaskResponse(error.response);
      setLoader2(false);
    }
  };

  return (
    <>
      <div>
        <Heading2 align="center">Update Task</Heading2>
      </div>
      <div className="cd-input-container">
        <Label1>Title:</Label1>
        <InputField
          placeholder="Enter task title"
          name="cd_task_title"
          value={updateTaskPayload.title}
          onChange={(e) => setUpdateTaskPayload({ ...updateTaskPayload, title: e.target.value })}
        ></InputField>
      </div>
      <div className="cd-input-container">
        <Label1>Description:</Label1>
        <InputField
          placeholder="Enter task description"
          name="cd_task_description"
          value={updateTaskPayload.description}
          onChange={(e) =>
            setUpdateTaskPayload({ ...updateTaskPayload, description: e.target.value })
          }
        ></InputField>
      </div>
      {loader ? (
        <div className="cd-success-msg">Loading Task Groups...</div>
      ) : (
        <div className="cd-input-container">
          <Label1>Task Group:</Label1>
          <select
            name="projectGroup"
            id="projectGroup"
            className="cd-input-field"
            value={updateTaskPayload.group_id}
            onChange={(e) =>
              setUpdateTaskPayload({ ...updateTaskPayload, group_id: e.target.value })
            }
          >
            <option value="">Select</option>
            {allTaskGroups?.task_groups?.map((task, index) => (
              <option key={index} value={task.guid}>
                {task.title}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="cd-input-container">
        <Label1>Estimated Hours:</Label1>
        <InputNumber
          placeholder="Enter required hours"
          name="cd_task_hours"
          min={0}
          value={updateTaskPayload.estimate_hours}
          onChange={(e) =>
            setUpdateTaskPayload({ ...updateTaskPayload, estimate_hours: e.target.value })
          }
        ></InputNumber>
      </div>
      <div className="cd-input-container">
        <Label1>Start date :</Label1>
        <InputDate
          placeholder="Enter task start date"
          name="cd_task_deadline"
          value={updateTaskPayload.start_date}
          onChange={(e) => {
            setUpdateTaskPayload({ ...updateTaskPayload, start_date: e.target.value });
          }}
        ></InputDate>
      </div>
      <div className="cd-input-container">
        <Label1>Deadline:</Label1>
        <InputDate
          placeholder="Enter task deadline"
          name="cd_task_deadline"
          value={updateTaskPayload.deadline_date}
          onChange={(e) => {
            setUpdateTaskPayload({ ...updateTaskPayload, deadline_date: e.target.value });
          }}
        ></InputDate>
      </div>
      <div className="cd-img-thumb-container">
        {uploadMediaPreview.map((preview, index) => (
          <img
            onClick={() => setFullSizeImage(preview)}
            key={index}
            className="cd_uploaded_image_thumb"
            src={preview}
            alt={`Preview ${index}`}
          />
        ))}
      </div>

      {fullSizeImage && (
        <Modal handleModal={() => setFullSizeImage()}>
          <img className="cd-img-full-preview" src={fullSizeImage} alt="image-preview" />
        </Modal>
      )}

      {/* code block to show the validation error */}
      {validationError.map((error, index) => {
        if (error?.size_error) {
          return (
            <div key={index} className="cd-err-msg">
              {error?.size_error}
            </div>
          );
        }
        if (error?.extension_error) {
          return (
            <div key={index} className="cd-err-msg">
              {error?.extension_error}
            </div>
          );
        }
      })}
      <Button1 onClick={() => setShowFileAttach(true)}>Attach Media</Button1>

      {showFileAttach && (
        <div className="cd-input-container">
          <InputFile
            className=""
            name="cd_task_media"
            multiple={true}
            onChange={(e) => handleUploadedImage(e.target.files)}
          ></InputFile>
        </div>
      )}
      {updateTaskResponse?.tuid && <div className="cd-success-msg">Task Updated Successfully</div>}
      {uploadMediaResponse?.status === 201 && <div className="cd-success-msg">Media uploaded</div>}
      {updateTaskResponse?.status !== 201 && (
        <div className="cd-err-msg">{updateTaskResponse?.data?.detail[0]?.msg}</div>
      )}
      {updateTaskResponse?.status !== 201 && (
        <div className="cd-err-msg">{updateTaskResponse?.data?.detail?.detail?.[0]?.msg}</div>
      )}
      <Button1 onClick={updateTask}>Update {loader2 && <Loader2 />}</Button1>
    </>
  );
};

export default TmUpdateTask;
