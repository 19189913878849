import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { calculateAmount } from "../../redux/slice/subscriptionSlice";

import axiosFetch from "../../axiosConfig";
import { CREATE_ORDER, CALCULATE_AMOUNT } from "../../api";
import Loader from "../../components/loaders/Loader";
import ApplyCoupon from "../../components/commoncomps/ApplyCoupon";

const StripeCreateOrder = ({ userInfo, suid }) => {
  const dispatch = useDispatch();

  const [stripe, setStripe] = useState();
  const [createOrderResponse, setCreateOrderResponse] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [elements, setElements] = useState();
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [applyCouponResponse, setApplyCouponResponse] = useState();
  // console.log(applyCouponResponse);

  useEffect(() => {
    dispatch(
      calculateAmount({
        product_id: suid,
        coupon_code: "",
        state: userInfo.state,
        country: userInfo.country,
        currency: localStorage.getItem("currencyCMS"),
      })
    );
  }, []);
  const {
    calculateAmountData: { amountDistribution },
  } = useSelector((state) => state.subscription);

  const applyCoupon = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(CALCULATE_AMOUNT, {
        product_id: suid,
        coupon_code: coupon,
        state: userInfo.state,
        country: userInfo.country,
        currency: localStorage.getItem("currencyCMS"),
      });
      setLoader2(false);
      setApplyCouponResponse(resp.data);
    } catch (error) {
      setLoader2(false);
    }
  };

  // getting Stripe API key from .env file
  const stripeKey = process.env.REACT_APP_STRIPE_API_KEY;

  // passing the API key in loadStripe, will return a promise which will be used further
  const stripePromise = loadStripe(stripeKey);

  // call the create order API on page render
  useEffect(() => {
    setLoader(true);
    const initializeStripe = async () => {
      // get the stripeInstance after stripePromise is fullfilled
      const stripeInstance = await stripePromise;
      setStripe(stripeInstance);

      // appearance configurations
      const appearance = {
        theme: "stripe",
      };

      // to integrate the stripe create order API and get the client secret
      try {
        const clientSecretResp = await axiosFetch.post(CREATE_ORDER, {
          name: userInfo.name,
          address_1: userInfo.address,
          address_2: userInfo.address,
          city: userInfo.city,
          suid: suid,
          postal_code: userInfo.pincode,
          country_code: "IN",
          currency: localStorage.getItem("currencyCMS"),
          state: userInfo.state,
          country: userInfo.country,
          phone: userInfo.phone,
          gateway: "stripe",
          coupon_code: coupon,
        });

        // if order created than mount the payment and authentication elements
        if (clientSecretResp.status === 201) {
          setLoader(false);
          setCreateOrderResponse(clientSecretResp.data);

          // Create Stripe elements
          const elementsInstance = stripeInstance.elements({
            clientSecret: clientSecretResp.data.client_secret,
            appearance: appearance,
          });
          setElements(elementsInstance);

          // Create and mount the Link Authentication Elements
          const linkAuthenticationElement = elementsInstance.create("linkAuthentication");
          linkAuthenticationElement.mount("#link-authentication-element");
          linkAuthenticationElement.on("change", (event) => {
            setEmail(event.value.email);
          });

          // Create and mount the Payment Elements
          const paymentElementOptions = {
            layout: "tabs",
            paymentMethodOrder: ["apple_pay", "google_pay", "card"],
            // payment_method_configuration: 'pmd_1OY0zKSBYQ15sd1tkESCRbsy'
          };
          const paymentElement = elementsInstance.create("payment", paymentElementOptions);
          paymentElement.mount("#payment-element");
        }
      } catch (error) {
        setLoader(false);
        setCreateOrderResponse(error.response.data);
      }
    };

    // function to check the status, that is the url has "payment_intent_client_secret" or not
    const checkStatus = async () => {
      const clientSecret = new URLSearchParams(window.location.url).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        initializeStripe();
        return;
      }

      // const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
      // console.log(paymentIntent);
    };

    checkStatus();
  }, [applyCouponResponse]);

  // function to handle the submit payment
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { error } = await stripe.confirmPayment({
      elements: elements,
      confirmParams: {
        return_url:
          window.location.origin + `/payment-success/${createOrderResponse?.ouid}/success`,
        receipt_email: email,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
  };
  return (
    <>
      {loader && <Loader />}
      <div className={`cd-stripe-checkout-container ${loader ? "not-appeared" : "appeared"}`}>
        {createOrderResponse?.total_amount ? (
          <div>
            <div className="cd-product-price">
              Price :
              <span className="cd-price-value">
                {createOrderResponse?.currency === "INR" ? "₹" : ""}
                {createOrderResponse?.total_amount}
              </span>
            </div>
            <div className="cd-product-tax-container">
              Service Charges :-{" "}
              {amountDistribution?.taxes?.map((tax) => (
                <div key={tax.taxuid} className="cd-product-tax-list">
                  <ul>
                    {tax.fields.map((field) => (
                      <li key={field.title} className="cd-product-tax-list-element">
                        {field.title} :{" "}
                        <span className="cd-price-value">
                          {" "}
                          {createOrderResponse?.currency === "INR" ? "₹" : ""}
                          {field.value}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className="cd-product-coupon">
              Coupon Code : {createOrderResponse?.coupon_code}
            </div>
            <div className="cd-product-coupon-amount">
              Coupon Amount :{" "}
              <span className="cd-price-value">
                {createOrderResponse?.currency === "INR" ? "₹" : ""}{" "}
                {createOrderResponse?.coupon_amount?.toFixed(2)}
              </span>
            </div>
            <div className="cd-product-final-price">
              Final Price :{" "}
              <span className="cd-price-value">
                {createOrderResponse?.currency === "INR" ? "₹" : ""}
                {createOrderResponse?.final_amount}
              </span>
            </div>
            {message && <div className="cd-err-msg">{message}</div>}
          </div>
        ) : (
          <div className="cd-err-msg">{createOrderResponse?.detail?.detail[0]?.msg}</div>
        )}

        <ApplyCoupon
          couponCode={coupon}
          onChange={(e) => setCoupon(e.target.value)}
          applyCoupon={applyCoupon}
          loader={loader2}
        ></ApplyCoupon>
        <div className="stripe-payment-container">
          <form id="payment-form" onSubmit={handleSubmit}>
            {/* JSX for your payment form */}
            <div id="link-authentication-element">
              {/* Stripe.js injects the Link Authentication Element */}
            </div>
            <div id="payment-element">{/* Stripe.js injects the Payment Element */}</div>
            <br />
            <button
              className="cd-btn"
              id="submit"
              disabled={createOrderResponse?.ouid ? false : true}
            >
              Pay Now
            </button>
            <div id="payment-message" className="">
              {/* {message} */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default StripeCreateOrder;
