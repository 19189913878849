import React from "react";

const InputPassword = (props) => {
  let className = props.className ? props.className : "";
  return (
    <input
      className={`cd-input-field ${className}`}
      id={props.id}
      type={props.type ? props.type : "password"}
      name={props.name}
      placeholder={props.placeholder}
      onChange={props.onChange}
      value={props.value}
    ></input>
  );
};

export default InputPassword;
