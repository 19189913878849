import React from "react";

const InputColor = (props) => {
  let className = props.className ? props.className : "";

  return (
    <input
      className={`${className}`}
      id={props.id}
      type="color"
      name={props.name}
      placeholder={props.placeholder}
      onChange={props.onChange}
      autoComplete="off"
      value={props.value ? props.value : "#ffffff"}
      checked={props.checked}
    ></input>
  );
};

export default InputColor;
