import React from "react";

const InputCheckbox = (props) => {
  let className = props.className ? props.className : "";
  return (
    <input
      className={`cd-input-field ${className}`}
      id={props.id}
      type="checkbox"
      name={props.name}
      placeholder={props.placeholder}
      onChange={props.onChange}
      autoComplete="off"
      value={props.value}
      checked={props.checked}
    ></input>
  );
};

export default InputCheckbox;
