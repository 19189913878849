import React from "react";
const DashboardSidebar = (props) => {
  let className = props.className ? props.className : "";
  return (
    <>
      <div className={`cd-dashboard-strip-controls ${className}`}>{props.children}</div>
    </>
  );
};

export default DashboardSidebar;
