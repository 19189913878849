import React from "react";

const Label1 = (props) => {
  let className = props.className ? props.className : "";
  return (
    <label className={`cd-input-label ${className}`} htmlFor={props.htmlFor} style={props.style}>
      {props.children}
    </label>
  );
};

export default Label1;
