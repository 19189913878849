import { React, useState } from "react";
import axiosFetch from "../../axiosConfig";
import { useNavigate, Navigate } from "react-router-dom";
import { CHANGE_PASSWORD_ENDPOINT } from "../../api";
import { useTranslation } from "react-i18next";
import Card1 from "../../components/cards/Card1";
import Button1 from "../../components/buttons/Button1";
import InputPassword from "../../components/inputs/InputPassword";
import Heading2 from "../../components/headings/Heading2";
import Loader from "../../components/loaders/Loader";

const ResetPassword = () => {
  // to get the parameter from URL bar
  const searchParams = new URLSearchParams(window.location.search);

  //to get token key from constant searchParams
  const token = searchParams.get("token");

  // constant to use useTranslation() hook
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [response, setResponse] = useState();
  const [loader, setLoader] = useState(false);

  //function to call change password API
  const submitChangePassword = async () => {
    setLoader(true);
    try {
      const resp = await axiosFetch.post(CHANGE_PASSWORD_ENDPOINT, {
        token: token,
        password: password2,
      });
      setResponse(resp);

      // if change password API gave success code than redirect the user login page
      if (resp.status === 201) {
        setLoader(false);
        // using set time so that user can read response message that user's password has changed
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      }
    } catch (error) {
      setResponse(error?.response);
    }
  };

  // constant to disable the Reset button
  const isDisabled = password1 !== password2 || password1.length === 0 ? true : false;
  if (!token) {
    return <Navigate to="/login"></Navigate>;
  }

  return (
    <Card1>
      <Heading2>{t("resetPassword")}</Heading2>
      <div className="cd-input-container">
        <label className="cd-input-label" htmlFor="">
          {t("enterNewPassword")}:
        </label>
        <InputPassword
          className="cd-input-field"
          name="cd_reset_password_input"
          placeholder={t("placeholderPassword")}
          onChange={(e) => setPassword1(e.target.value)}
        />
      </div>
      <div className="cd-input-container">
        <label className="cd-input-label" htmlFor="">
          {t("confirmPswrd")}:
        </label>
        <InputPassword
          className="cd-input-field"
          name="cd_reset_password_input_confirm"
          placeholder={t("placeholderConfirmPswrd")}
          onChange={(e) => setPassword2(e.target.value)}
        />
      </div>
      {/* code block the success msg */}
      <div>
        {response?.status === 201 && (
          <div className="cd-success-msg">{t("passwordChanged")}, Redirecting...</div>
        )}
      </div>

      {/* code block to show the error if user got any */}
      <div>
        {response?.status !== 200 && (
          <div className="cd-err-msg">{response?.data?.detail?.detail[0]?.msg}</div>
        )}
      </div>

      {loader && <Loader />}
      <div>
        <Button1 onClick={submitChangePassword} className="cd-btn" disabled={isDisabled}>
          {t("reset")}
        </Button1>
      </div>
    </Card1>
  );
};
export default ResetPassword;
