import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllComments } from "../../redux/slice/taskManagementSlice";
import { TmADD_COMMENT_IN_TASK, IMAGE_PATH_PREFIX } from "../../api";
import TextEditor1 from "../../components/texteditors/TextEditor1";
import Heading2 from "../../components/headings/Heading2";
import Button1 from "../../components/buttons/Button1";
import Loader from "../../components/loaders/Loader";
import Loader2 from "../../components/loaders/Loader2";
import axiosFetch from "../../axiosConfig";

const TmAddTaskComment = ({ taskId }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(true);
  const [loader3, setLoader3] = useState(false);
  const [commentInput, setCommentInput] = useState("");
  const [commentResp, setCommentResp] = useState({});
  const [limit, setLimit] = useState(10);
  const [selectedComment, setSelectedComment] = useState(null);
  const [commentReply, setCommentReply] = useState("");

  // payload for comment
  let addCommentPayload = {
    task_uid: taskId,
    comment: commentInput,
  };

  // payload for reply
  let addReplyPayload = {
    task_uid: taskId,
    comment: commentReply,
    parent_id: selectedComment,
  };

  // api call to add comment
  const addComment = async (action) => {
    setLoader(true);
    // action to specify wheather its comment or reply
    let payload = action === "comment" ? addCommentPayload : addReplyPayload;
    try {
      const resp = await axiosFetch.post(TmADD_COMMENT_IN_TASK, payload);
      setCommentResp(resp.data);
      setCommentInput(""); // make comment input blank
      setCommentReply(""); // make comment reply blank
      setLoader(false);
    } catch (error) {
      setLoader(false);
      setCommentResp(error.response);
    }
  };

  // dispatch action to get all comments
  useEffect(() => {
    setLoader2(true);
    dispatch(
      getAllComments({
        tuid: taskId,
        limit: limit,
      })
    ).then(() => {
      setLoader2(false);
      setLoader3(false);
    });
  }, [commentResp, limit]);

  const {
    getAllComments: { allComments },
  } = useSelector((state) => state.taskManagement);

  // function to load more comment
  const loadComments = () => {
    setLimit(limit + 5); // add 5 more comments
    setLoader2(true);
    setLoader3(true);
  };

  // to disable the "load more" button
  const isDisabled = limit >= allComments?.total ? true : false;

  return (
    <div>
      <Heading2>Comments</Heading2>
      {loader2 && <Loader />}
      <div className={`cd-comment-creator-section ${loader2 ? "not-appeared" : "appeared"}`}>
        {allComments?.result && allComments?.result?.length > 0 ? (
          allComments?.result?.map((comments, index) => (
            <div className="cd-comment-container" key={index}>
              <div className="cd-comment-creator-info clearfix">
                <div className="cd-comment-creator-profile">
                  <img
                    src={IMAGE_PATH_PREFIX + comments.comment.creator.profile_photo}
                    alt=""
                    style={{
                      height: "35px",
                      width: "35px",
                      border: "1px solid rgb(208, 212, 228)",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className="cd-creator-name">
                  <span>{comments.comment.creator.username}</span>
                </div>
                <div className="cd-comment-time">
                  <span>{cdFormatCommentTime(comments.comment.created_at)}</span>
                </div>
              </div>
              <div
                className="cd-comment-content"
                dangerouslySetInnerHTML={{ __html: comments.comment.comment }}
              ></div>
              <div className="cd-comment-actions">
                <div className="cd-comment-reply">
                  <span
                    className="cd-replay-btn"
                    onClick={() =>
                      setSelectedComment(
                        selectedComment === comments.comment.cuid ? null : comments.comment.cuid
                      )
                    }
                  >
                    Reply
                  </span>
                </div>
                {selectedComment === comments.comment.cuid && (
                  <div>
                    <TextEditor1 value={commentReply} onChange={setCommentReply} />
                    <Button1 onClick={() => addComment("reply")}>Add Reply</Button1>
                  </div>
                )}
              </div>
              <div className="cd-reply-list">
                <div className="cd-reply-container">
                  {comments?.replies?.map((reply) => (
                    <div key={reply.cuid}>
                      <div className="cd-reply-content clearfix">
                        <div className="cd-reply-creator-profile">
                          <img
                            src={IMAGE_PATH_PREFIX + reply?.creator?.profile_photo}
                            alt=""
                            style={{
                              height: "35px",
                              width: "35px",
                              border: "1px solid rgb(208, 212, 228)",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                        <div className="cd-reply-text-box">
                          <div
                            className="cd-reply-text"
                            dangerouslySetInnerHTML={{ __html: reply?.comment }}
                          ></div>
                          <div className="cd-reply-time">
                            <span>{cdFormatCommentTime(reply?.created_at)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No Comments available</p>
        )}
      </div>
      <Button1 className="cd-load-comments" onClick={loadComments} disabled={isDisabled}>
        Load More {loader3 && <Loader2 />}
      </Button1>
      <TextEditor1 value={commentInput} onChange={setCommentInput} />
      <Button1 onClick={() => addComment("comment")}> Add Comment{loader && <Loader2 />} </Button1>
    </div>
  );
};

const cdFormatCommentTime = (timestamp) => {
  const cdDateTime = new Date(timestamp);

  // get client's timezone
  const cdClientTimeZoneOffset = new Date().getTimezoneOffset();

  const minutesDiff = Math.floor(
    (Date.now() - cdDateTime.getTime() + cdClientTimeZoneOffset * 60 * 1000) / (60 * 1000)
  ); // get the time difference in minutes from current time
  let formattedDateTime;

  if (minutesDiff < 1) {
    formattedDateTime = "just now";
  } else if (minutesDiff < 60) {
    formattedDateTime = `${minutesDiff} mins ago`;
  } else if (minutesDiff < 1440) {
    let hoursDiff = Math.floor(minutesDiff / 60);
    formattedDateTime = `${hoursDiff} hours ago`;
  } else {
    formattedDateTime = cdDateTime.toLocaleString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  return formattedDateTime;
};

export default TmAddTaskComment;
