import { React, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useTranslation } from "react-i18next";

import { ORGANISATION_DETAILS, ORGANIZATION_UPDATE_DRIVE_TOKEN } from "../../api";
import axiosFetch from "../../axiosConfig";
import OrganisationWrapper from "../../components/wrappers/OrganisationWrapper";
import Heading2 from "../../components/headings/Heading2";
import Button1 from "../../components/buttons/Button1";
import Modal from "../../components/modal/Modal";
import TmCreateProject from "../taskManagement/TmCreateProject";
import OrganisationUpdate from "./OrganisationUpdate";

const OrganisationDetails = ({ setProgress }) => {
  const { slug } = useParams(); // get slug from url param
  const { t } = useTranslation(); // constant to use this hook
  localStorage.setItem("orgslug", slug); // set in localStorage
  localStorage.removeItem("last-task"); //remove lask task path on org change
  const user = JSON.parse(localStorage.getItem("user"));

  const [organisationDetails, setOrganisationDetails] = useState();
  const [loader, setLoader] = useState(true);
  const [openModalToCreateProject, setOpenModalToCreateProject] = useState(false);
  const [openUpdateOrgModal, setOpenUpdateOrgModal] = useState(false);
  const [organisationUpdate, setOrganisationUpdate] = useState(false);
  const [updateDriveTokenResponse, setUpdateDriveTokenResponse] = useState({});
  // getting organisation details
  useEffect(() => {
    setProgress(40);
    const getOrganisationDetails = async () => {
      setLoader(true);
      try {
        const resp = await axiosFetch.get(ORGANISATION_DETAILS, {
          headers: {
            slug: slug,
          },
        });
        setOrganisationDetails(resp.data);
        setProgress(100);
        setLoader(false);
      } catch (error) {
        setOrganisationDetails(error.response);
        setLoader(false);
        setProgress(100);
      }
    };
    getOrganisationDetails();
  }, [slug, organisationUpdate, updateDriveTokenResponse]);

  // function to create drive connection
  const connectDrive = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const resp = await axiosFetch.post(ORGANIZATION_UPDATE_DRIVE_TOKEN, {
          code: response.code,
        });
        setUpdateDriveTokenResponse(resp);
      } catch (error) {
        // console.log(error.response);
        setUpdateDriveTokenResponse(error.response);
      }
    },
    flow: "auth-code",
    scope: "profile email https://www.googleapis.com/auth/drive.file",
  });

  // show message if organization is not active
  if (
    organisationDetails?.organization?.is_active === false &&
    user.email !== organisationDetails?.organization?.admin?.email
  ) {
    return (
      <OrganisationWrapper>
        <div className="cd-err-msg">
          <h3>
            The organisation has been deactivated
            <br />
            Contact to admin or select another organisation
          </h3>
          <Link to="/all-organisations" className="cd-common-anchor-tag">
            Go to Organisation List
          </Link>
        </div>
      </OrganisationWrapper>
    );
  }

  // return the error msg if got any
  if (organisationDetails?.data?.detail) {
    return (
      <OrganisationWrapper>
        <>
          <div className="cd-err-msg">{organisationDetails?.data?.detail?.detail[0]?.msg}</div>
          <div>
            <Link className="cd-common-anchor-tag cd-success-msg" to="/all-subscriptions">
              Click here to buy a subscription
            </Link>
          </div>
        </>
      </OrganisationWrapper>
    );
  }
  return (
    <OrganisationWrapper>
      <Heading2>{t("orgDetails")} </Heading2>
      <div className={`cd-organisations-list-element ${loader ? "not-appeared" : "appeared"}`}>
        <div>
          {t("name")} : {organisationDetails?.organization?.title}
        </div>
        <div>
          {t("adminEmail")} : {organisationDetails?.organization?.admin?.email}
        </div>
        <div>
          {t("adminUserName")} : {organisationDetails?.organization?.admin?.username}
        </div>
      </div>
      <div>
        <Link to={`/${slug}/users`} className="cd-common-anchor-tag">
          {t("user")}
        </Link>
      </div>
      <div>
        <Link to={`/${slug}/roles`} className="cd-common-anchor-tag">
          {t("roles")}
        </Link>
      </div>
      <div>
        <Link to={`/${slug}/projects`} className="cd-common-anchor-tag">
          {t("projects")}
        </Link>
      </div>
      <div>
        <Button1 onClick={() => setOpenUpdateOrgModal(!openUpdateOrgModal)}>{t("update")} </Button1>
      </div>
      {openUpdateOrgModal && (
        <Modal handleModal={() => setOpenUpdateOrgModal(!openUpdateOrgModal)}>
          <OrganisationUpdate
            organisationUpdate={() => setOrganisationUpdate(!organisationUpdate)}
            organisationDetails={organisationDetails?.organization}
          />
        </Modal>
      )}
      <div>
        <Button1 onClick={(e) => setOpenModalToCreateProject(!openModalToCreateProject)}>
          {t("createProject")}
        </Button1>
      </div>
      {/* modal to create a new project */}
      {openModalToCreateProject && (
        <Modal handleModal={() => setOpenModalToCreateProject(!openModalToCreateProject)}>
          <TmCreateProject />
        </Modal>
      )}
      {/* show the drive connection button conditionally */}
      {organisationDetails?.organization?.gtoken === null && (
        <div>
          <Button1 onClick={connectDrive}>{t("connectDrive")} </Button1>
        </div>
      )}
      {/* show the error message conditionally */}
      {updateDriveTokenResponse?.data?.detail && (
        <div className="cd-err-msg">{updateDriveTokenResponse?.data?.detail[0]?.msg}</div>
      )}
      {updateDriveTokenResponse?.data?.detail?.detail && (
        <div className="cd-err-msg">{updateDriveTokenResponse?.data?.detail?.detail[0]?.msg}</div>
      )}
    </OrganisationWrapper>
  );
};

export default OrganisationDetails;
