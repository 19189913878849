import { React, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { getAllTags } from "../../redux/slice/taskManagementSlice";
import { TmCREATE_TAG } from "../../api";
import Heading2 from "../../components/headings/Heading2";
import Label1 from "../../components/labels/Label1";
import InputField from "../../components/inputs/InputField";
import InputColor from "../../components/inputs/InputColor";
import Button1 from "../../components/buttons/Button1";
import axiosFetch from "../../axiosConfig";
import Loader2 from "../../components/loaders/Loader2";

const TmCreateTag = ({ projectId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loader2, setLoader2] = useState(false);
  const [createTagResponse, setCreateTagResponse] = useState({});
  const [createTagPayload, setCreateTagPayload] = useState({
    project_id: projectId,
    title: "",
    color: "",
  });

  const isDisabled =
    createTagPayload.title.length <= 2 || createTagPayload.color.length <= 0 ? true : false;

  // call the API of create tag
  const createTag = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(TmCREATE_TAG, createTagPayload);
      setLoader2(false);
      setCreateTagResponse(resp.data);
      if (resp.status === 201) {
        dispatch(
          getAllTags({
            projectId: projectId,
          })
        );
      }
    } catch (error) {
      setLoader2(false);
      setCreateTagResponse(error.response);
    }
  };
  return (
    <>
      <Heading2 align="center">{t("createTag")} </Heading2>
      <div>
        <div className="cd-input-container">
          <Label1>{t("name")} :</Label1>
          <InputField
            placeholder={t("placeholderTagTitle")}
            name="cd_tag_title"
            value={createTagPayload.title}
            onChange={(e) => setCreateTagPayload((prev) => ({ ...prev, title: e.target.value }))}
          ></InputField>
        </div>
        <div className="cd-input-container">
          <Label1>{t("color")} :</Label1>
          <InputColor
            value={createTagPayload.color}
            name="cd_tag_color"
            onChange={(e) => setCreateTagPayload((prev) => ({ ...prev, color: e.target.value }))}
          ></InputColor>
        </div>
        {/* showing the API response conditionally */}
        {createTagResponse?.tuid && <div className="cd-success-msg">{t("tagCreatedMsg")}! </div>}
        {createTagResponse?.data?.detail && (
          <div className="cd-err-msg">{createTagResponse?.data?.detail[0]?.msg}</div>
        )}
        {createTagResponse?.data?.detail?.detail && (
          <div className="cd-err-msg">{createTagResponse?.data?.detail?.detail[0]?.msg}</div>
        )}
        <Button1 onClick={createTag} disabled={isDisabled}>
          {t("create")} {loader2 && <Loader2 />}
        </Button1>
      </div>
    </>
  );
};

export default TmCreateTag;
