import { React, useState } from "react";
import { useTranslation } from "react-i18next";

import { TmPROJECT_UPDATE } from "../../api";
import axiosFetch from "../../axiosConfig";
import Label1 from "../../components/labels/Label1";
import InputField from "../../components/inputs/InputField";
import TextArea from "../../components/inputs/TextArea";
import Loader2 from "../../components/loaders/Loader2";
import Heading2 from "../../components/headings/Heading2";
import Button1 from "../../components/buttons/Button1";

const TmUpdateProject = ({ projectDetails, puid }) => {
  const { t } = useTranslation(); // constant to use this hook
  const [loader2, setLoader2] = useState(false);
  const [updateProjectResponse, setUpdateProjectResponse] = useState({});
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  const [updateProjectPayload, setUpdateProjectPayload] = useState({
    project_id: puid,
    title: projectDetails.title ? projectDetails.title : "",
    description: projectDetails.description ? projectDetails.description : "",
    is_active: projectDetails.is_active ? projectDetails.is_active : "",
    is_deleted: false,
  });

  // call the API of update project
  const updateProject = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(TmPROJECT_UPDATE, updateProjectPayload);
      setLoader2(false);
      setUpdateProjectResponse(resp.data);
    } catch (error) {
      setLoader2(false);
      setUpdateProjectResponse(error.response);
    }
  };

  return (
    <>
      <Heading2 align="center">{t("updateProject")} </Heading2>
      <div className="cd-input-container">
        <Label1>{t("name")} :</Label1>
        <InputField
          placeholder={t("placeholderProjectTitle")}
          name="cd_project_title"
          value={updateProjectPayload.title}
          onChange={(e) =>
            setUpdateProjectPayload({ ...updateProjectPayload, title: e.target.value })
          }
        ></InputField>
      </div>
      <div className="cd-input-container">
        <Label1>{t("description")} :</Label1>
        <TextArea
          placeholder={t("placeholderProjectDesc")}
          value={updateProjectPayload.description}
          onChange={(e) =>
            setUpdateProjectPayload({ ...updateProjectPayload, description: e.target.value })
          }
          rows="5"
          cols="30"
        ></TextArea>
      </div>
      <Button1 onClick={() => setShowChangeStatus(!showChangeStatus)}>{t("activeStatus")} </Button1>
      {showChangeStatus && (
        <div>
          <select
            name="activityStatus"
            id="activityStatus"
            className="cd-input-field"
            value={updateProjectPayload.is_active}
            onChange={(e) =>
              setUpdateProjectPayload({ ...updateProjectPayload, is_active: e.target.value })
            }
          >
            <option value={true}>{t("active")} </option>
            <option value={false}>{t("deactivate")} </option>
          </select>
        </div>
      )}
      <div className="cd-msg-container">
        {updateProjectResponse?.puid && (
          <div className="cd-success-msg">{t("projectUpdatedMsg")} !</div>
        )}
        {updateProjectResponse?.data?.detail && (
          <div className="cd-err-msg">{updateProjectResponse?.data?.detail[0].msg}</div>
        )}
        {updateProjectResponse?.data?.detail?.detail && (
          <div className="cd-err-msg">{updateProjectResponse?.data?.detail?.detail[0].msg}</div>
        )}
      </div>
      <Button1 onClick={updateProject}>
        {t("update")} {loader2 && <Loader2 />}
      </Button1>
    </>
  );
};

export default TmUpdateProject;
