import React from "react";

const TextArea = (props) => {
  let className = props.className ? props.className : "";
  return (
    <textarea
      className={`cd-input-field ${className}`}
      name={props.name}
      placeholder={props.placeholder}
      id={props.id}
      cols={props.cols}
      rows={props.rows}
      value={props.value}
      onChange={props.onChange}
    >
      {props.children}
    </textarea>
  );
};

export default TextArea;
