import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { getProjects } from "../../redux/slice/taskManagementSlice";
import { TmCREATE_PROJECT } from "../../api";
import Heading2 from "../../components/headings/Heading2";
import InputField from "../../components/inputs/InputField";
import TextArea from "../../components/inputs/TextArea";
import Label1 from "../../components/labels/Label1";
import Button1 from "../../components/buttons/Button1";
import Loader2 from "../../components/loaders/Loader2";
import axiosFetch from "../../axiosConfig";

const TmCreateProject = ({ limit, offset }) => {
  const { t } = useTranslation(); // const to use this hook
  const dispatch = useDispatch(); // constant to use this hook
  const [loader2, setLoader2] = useState(false);
  const [createProjectResponse, setCreateProjectResponse] = useState();
  const [createProjectPayload, setCreateProjectPayload] = useState({
    title: "",
    description: "",
  });

  // call the API of create project
  const createProject = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(TmCREATE_PROJECT, createProjectPayload);
      setCreateProjectResponse(resp.data);
      setLoader2(false);
      if (resp.status === 201) {
        dispatch(
          getProjects({
            limit: limit,
            offset: offset,
          })
        );
      }
    } catch (error) {
      setCreateProjectResponse(error.response);
      setLoader2(false);
    }
  };

  // constant to disable the submit button if projectTitle or projectDescription are blank
  const isDisabled =
    createProjectPayload.title.length === 0 || createProjectPayload.description.length <= 10
      ? true
      : false;

  return (
    <>
      <Heading2 align="center">{t("createProject")} </Heading2>
      <div className="cd-input-container">
        <Label1>{t("name")} :</Label1>
        <InputField
          placeholder={t("placeholderProjectTitle")}
          name="cd_project_title"
          value={createProjectPayload.title}
          onChange={(e) => setCreateProjectPayload((prev) => ({ ...prev, title: e.target.value }))}
        ></InputField>
      </div>
      <div className="cd-input-container">
        <Label1>{t("description")} :</Label1>
        <TextArea
          placeholder={t("placeholderProjectDesc")}
          name="cd_project_description"
          value={createProjectPayload.description}
          onChange={(e) =>
            setCreateProjectPayload((prev) => ({ ...prev, description: e.target.value }))
          }
        ></TextArea>
      </div>
      <Button1 onClick={createProject} disabled={isDisabled}>
        {t("create")} {loader2 && <Loader2 />}
      </Button1>
      {createProjectResponse?.puid && (
        <div className="cd-success-msg">{t("projectCreatedMsg")} !</div>
      )}
      {createProjectResponse?.data?.detail && (
        <div className="cd-msg-container">
          <div className="cd-err-msg">{createProjectResponse?.data?.detail[0]?.msg}</div>
        </div>
      )}
      {createProjectResponse?.data?.detail?.detail && (
        <div className="cd-msg-container">
          <div className="cd-err-msg">{createProjectResponse?.data?.detail?.detail[0]?.msg}</div>
        </div>
      )}
    </>
  );
};

export default TmCreateProject;
