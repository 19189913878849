import React from "react";

const Card1 = (props) => {
  let className = props.className ? props.className : "";
  return (
    <div className="cd_card_container">
      <div className={`cd_card ${className}`}>{props.children}</div>
    </div>
  );
};

export default Card1;
