import React from "react";

const Button1 = (props) => {
  let className = props?.className ? props.className : "";
  return (
    <div className="cd-btn-container">
      <button
        id={props.id}
        onClick={props.onClick}
        className={`cd-btn ${className}`}
        type={props.type}
        disabled={props.disabled}
      >
        {props.children}
      </button>
    </div>
  );
};

export default Button1;
