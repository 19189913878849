import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getAllTaskGroups } from "../../redux/slice/taskManagementSlice";
import OrganisationWrapper from "../../components/wrappers/OrganisationWrapper";
import Heading2 from "../../components/headings/Heading2";
import Button1 from "../../components/buttons/Button1";
import Modal from "../../components/modal/Modal";
import TmUpdateTaskGroup from "./TmUpdateTaskGroup";
import Pagination from "../../components/pagination/Pagination";
import GoToPage from "../../components/pagination/GoToPage";

const TmAllTaskGroups = ({ setProgress }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [limit] = useState(10);
  const [groupToBeUpdate, setGroupToBeUpdate] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [inputPage, setInputPage] = useState("");
  const [loader, setLoader] = useState(false);

  // Use useParams to get the route parameters
  const { puid } = useParams();

  // to get all task groups
  useEffect(() => {
    setLoader(true);
    setProgress(40);
    dispatch(
      getAllTaskGroups({
        projectId: puid,
        limit: limit,
        offset: limit * (currentPage - 1),
      })
    ).then(() => {
      setLoader(false);
      setProgress(100);
    });
  }, [currentPage]);

  // getting all task groups from taskManagement state
  const {
    getTaskGroups: { allTaskGroups },
  } = useSelector((state) => state.taskManagement);

  // functions to handle the pagination
  const handlePrev = () => {
    if (currentPage <= 1) return;
    setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage >= Math.ceil(allTaskGroups?.total / limit)) return;
    setCurrentPage(currentPage + 1);
  };

  const handleInputPage = () => {
    if (inputPage < 1 || inputPage > Math.ceil(allTaskGroups?.total / limit)) return;
    setCurrentPage(parseInt(inputPage));
  };

  return (
    <OrganisationWrapper>
      <Heading2>{t("taskGroups")} </Heading2>
      {allTaskGroups?.task_groups?.map((task_group, index) => (
        <div
          className={`cd-task-group-list-element ${loader ? "not-appeared" : "appeared"}`}
          key={index}
        >
          <div>
            <div>
              {t("name")}: {task_group.title}
            </div>
            {task_group.tasks.length !== 0 ? (
              <div>
                <div>{t("tasks")} :</div>
                <div>
                  {task_group?.tasks?.map((task, index1) => (
                    <div key={index1}>
                      {index1 + 1}. {task.title}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="cd-success-msg">{t("noTaskAssigned")} </div>
            )}
          </div>
          <Button1 onClick={() => setGroupToBeUpdate({ task_groups: task_group, index: index })}>
            {t("updateTaskGroup")}
          </Button1>
          {/* to open the modal of update task group */}
          {groupToBeUpdate?.index === index && (
            <Modal handleModal={() => setGroupToBeUpdate()}>
              <TmUpdateTaskGroup
                taskGroup={groupToBeUpdate.task_groups}
                puid={puid}
                limit={limit}
                offset={limit * (currentPage - 1)}
              />
            </Modal>
          )}
        </div>
      ))}
      {allTaskGroups?.total !== 0 && (
        <div>
          <Pagination
            onClickPrev={handlePrev}
            onClickNext={handleNext}
            currentPage={currentPage}
          ></Pagination>
          <GoToPage
            inputPage={inputPage}
            onClick={handleInputPage}
            onChange={(e) => setInputPage(e.target.value)}
          ></GoToPage>
        </div>
      )}
    </OrganisationWrapper>
  );
};

export default TmAllTaskGroups;
