import { React, useState } from "react";
import { useDispatch } from "react-redux";

import { TmCREATE_TASK } from "../../api";
import { getProjectTasks } from "../../redux/slice/taskManagementSlice";
import axiosFetch from "../../axiosConfig";
import Heading2 from "../../components/headings/Heading2";
import Label1 from "../../components/labels/Label1";
import InputField from "../../components/inputs/InputField";
import InputDate from "../../components/inputs/InputDate";
import InputNumber from "../../components/inputs/InputNumber";
import Button1 from "../../components/buttons/Button1";
import Loader2 from "../../components/loaders/Loader2";

function TmAddSubTask({ task, projectId, limit, currentPage }) {
  const dispatch = useDispatch(); // constant to use this hook
  const [createSubTaskResponse, setCreateSubTaskResponse] = useState({});
  const [loader, setLoader] = useState(false);
  // create sub task payload
  const [createSubTaskPayload, setCreateSubTaskPayload] = useState({
    title: "",
    description: "",
    project_id: task.project.puid,
    parent_id: task.tuid,
    group_id: task.group?.guid,
    estimate_hours: "",
    deadline_date: new Date().toISOString(),
  });
  const createSubTask = async () => {
    setLoader(true);
    try {
      const resp = await axiosFetch.post(TmCREATE_TASK, createSubTaskPayload);
      if (resp.status === 201) {
        setCreateSubTaskResponse(resp.data);
        setLoader(false);
        dispatch(
          getProjectTasks({
            projectId: projectId,
            limit: limit,
            offset: limit * (currentPage - 1),
          })
        );
      }
    } catch (error) {
      setCreateSubTaskResponse(error.response);
      setLoader(false);
    }
  };

  // constant to disable the create button
  const isDisabled =
    createSubTaskPayload.title.length === 0 ||
    createSubTaskPayload.description.length === 0 ||
    !task.group?.guid
      ? true
      : false;

  return (
    <div>
      <div>
        <Heading2 align="center">Add Subtask</Heading2>
      </div>
      <div>
        <div className="cd-input-container">
          <Label1>Title:</Label1>
          <InputField
            placeholder="Enter subtask title"
            name="cd_subtask_title"
            value={createSubTaskPayload.title}
            onChange={(e) =>
              setCreateSubTaskPayload((prev) => ({ ...prev, title: e.target.value }))
            }
          ></InputField>
        </div>
        <div className="cd-input-container">
          <Label1>Description:</Label1>
          <InputField
            placeholder="Enter subtask description"
            value={createSubTaskPayload.description}
            name="cd_subtask_desc"
            onChange={(e) =>
              setCreateSubTaskPayload((prev) => ({ ...prev, description: e.target.value }))
            }
          ></InputField>
        </div>
        <div className="cd-input-container">
          <Label1>Estimated Hours:</Label1>
          <InputNumber
            placeholder="Enter required hours"
            min={0}
            value={createSubTaskPayload.estimate_hours}
            name="cd_subtask_hrs"
            onChange={(e) =>
              setCreateSubTaskPayload((prev) => ({ ...prev, estimate_hours: e.target.value }))
            }
          ></InputNumber>
        </div>
        <div className="cd-input-container">
          <Label1>Deadline:</Label1>
          <InputDate
            placeholder="Enter task deadline"
            value={createSubTaskPayload.deadline_date}
            name="cd_subtask_deadline"
            onChange={(e) => {
              setCreateSubTaskPayload((prev) => ({ ...prev, deadline_date: e.target.value }));
            }}
          ></InputDate>
        </div>
        {/* Showing the API response */}
        {createSubTaskResponse?.title && (
          <div className="cd-success-msg">Subtask Created Successfully!</div>
        )}
        {createSubTaskResponse?.status !== 201 && (
          <div className="cd-err-msg">{createSubTaskResponse?.data?.detail[0]?.msg}</div>
        )}
        {createSubTaskResponse?.status !== 201 && (
          <div className="cd-err-msg">{createSubTaskResponse?.data?.detail?.detail[0]?.msg}</div>
        )}
        <Button1 onClick={createSubTask} disabled={isDisabled}>
          Create {loader && <Loader2 />}
        </Button1>
      </div>
    </div>
  );
}

export default TmAddSubTask;
