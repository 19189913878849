import React from "react";

const Loader = (props) => {
  let className = props.className ? props.className : "";
  return (
    <div className={className}>
      <div id="loader" className="cd_loader"></div>
      <div className="cd_loader_content">{props.children}</div>
    </div>
  );
};

export default Loader;
