import { React, useState } from "react";
import { useDispatch } from "react-redux";

import { getProjectDetails } from "../../redux/slice/taskManagementSlice";
import { TmDELETE_CUSTOM_COLUMN } from "../../api";
import Heading2 from "../../components/headings/Heading2";
import Button1 from "../../components/buttons/Button1";
import Loader2 from "../../components/loaders/Loader2";
import axiosFetch from "../../axiosConfig";

const TmDeleteCustomColumn = ({ columnId, projectId, handleModal }) => {
  const dispatch = useDispatch();
  const [loader2, setLoader2] = useState(false);
  const [deleteColumnResp, setDeleteColumnResp] = useState({});

  //function to call the API of delete custom column
  const deleteColumn = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.delete(TmDELETE_CUSTOM_COLUMN + `?column_id=${columnId}`);
      setDeleteColumnResp(resp);
      if (resp.status === 200) {
        // dispatch the getProjectDetails action to refresh the projectDetail data
        dispatch(
          getProjectDetails({
            projectId: projectId,
          })
        );
      }
      setLoader2(false);
    } catch (error) {
      setDeleteColumnResp(error.response);
      setLoader2(false);
    }
  };

  return (
    <div>
      <Heading2>Delete Column</Heading2>
      <p>Are you sure you want to delete this column?</p>
      {/* Additional content for the modal */}
      <div>
        <Button1 onClick={() => deleteColumn()}>Yes {loader2 && <Loader2 />}</Button1>
        <Button1 onClick={() => handleModal()}>No</Button1>
      </div>
      <div className="cd-msg-container">
        {deleteColumnResp?.status === 200 && (
          <div className="cd-success-msg">Column Deleted Successfully</div>
        )}
        {deleteColumnResp?.status !== 200 && (
          <div className="cd-err-msg">{deleteColumnResp?.data?.detail?.detail[0].msg}</div>
        )}
      </div>
    </div>
  );
};

export default TmDeleteCustomColumn;
